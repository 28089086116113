<template>
    <div class="container">
    <!-- <div class=""> -->
        <modal-work-history ref="modalWorkHistory" :task="selectedTask" @onUpdate="updateTask" :workOrderId="workOrderId" />

        <select name="sum-filter-select" id="sum-filter-select" v-model="selectedSumFilter" @change="updatedSumFilter">
            <option value="CREATED">Nespremni</option>
            <option value="READY">U toku</option>
            <option value="NOT_READY">Ponisteni, nespremni</option>
            <option value="IN_PROGRESS">U toku</option>
            <option value="DONE">Zavrseni</option>

        </select>
        
        <div>
            

            <!-- <select class="form-select form-select-lg mb-3" v-model="selectedGroup" aria-label=".form-select-lg groupselect" @change="updatePositionsColumns">
                <option value="-1" selected disabled>Izaberite grupu</option>
                <option v-for="positionGroup in positionGroups" :key="positionGroup.id" :value="positionGroup.id">{{positionGroup.name}} {{positionGroup.deleted?" &lt;&lt; OBRISAN >>":""}}</option>
            </select> -->


            <position-location-grid ref="positionLocationGrid" :tasksGridData="tasksGridData" :taskStatusSum="[selectedSumFilter]" @openHistoryModal="openHistoryModal" />
        </div>
    </div>
</template>

<script>
import WorkOrderService from '@/service/WorkOrderService';
import UtilService from '@/service/UtilService';
import {mapState} from 'vuex'
import ModalWorkHistory from '@/components/modals/ModalWorkHistory.vue';
import { Filter, Edit, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, CommandColumn } from "@syncfusion/ej2-vue-treegrid";    
import GridBasicTaskTemplate from '@/components/grid-templates/tasks/GridBasicTaskTemplate.vue';
import PositionLocationGrid from './PositionLocationGrid.vue';

// import WorkPositionGroupButtons from '../work-positions/WorkPositionGroupButtons.vue';


export default {
    components: { ModalWorkHistory, PositionLocationGrid },

    provide: {
        treegrid: [ Filter, Edit, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, CommandColumn ]
    },

    data () {
        return {
            initialLoadGrid: true,
            tasksGridData: {
                columns: [],
                rows: [],
            },
            selectedTask: null,
            selectedSumFilter: "CREATED",

            gridBasicTaskTemplate(taskId) {
                return () => {
                    return {
                        template: {
                            extends: GridBasicTaskTemplate,
                            propsData: {taskId: taskId, taskFilter: this.selectedSumFilter},

                        }
                    }
                }

            },
        }

        
    },

    computed: {
        ...mapState([
        'position_units',
        ]),

        workOrderId() {
            return Number(this.$route.params.woId);
        },

        workPosLength() {
            return this.workPositionsList?.length;
        },

    },

    methods: {
        workLabel : UtilService.workLabel,
        workClass : UtilService.workClass,


        taskAccessor(field, data) {            
            if (field == "key") return data.key;

            let fieldToken = field.split(".")
            
            if (data.children?.length > 0){
                let count = data.tasks_summary?.at(fieldToken[1])[this.selectedSumFilter];
                return count
            }

            return data.tasks?.at(fieldToken[1])?.task_status;
        },

        taskHeaderAccessor(workPosition) {
            return workPosition.name
        },


        dataBound() {
            if(this.initialLoadGrid === true) {
                this.$refs.locationPositionGrid.ej2Instances.collapseAll();
                this.initialLoadGrid = false;
            }
        },

        updatedSumFilter() {
            this.$refs.positionLocationGrid.refreshGrid();
        },

        /////

        getTaskButtonLabel(task) {
            if (!task) return "?"
            let task_status = task.task_status

            if (task_status == "CREATED") return "/"
            if (task_status == "READY") return "Spremno"
            if (task_status == "NOT_READY") return "/!"
            if (task_status == "IN_PROGRESS") return "U toku"
            if (task_status == "DONE") return "Završeno"
        },

        getTaskButtonClass(task) {
            if (!task) return "?"
            let task_status = task.task_status

            if (task_status == "CREATED") return "task-created"
            if (task_status == "READY") return "task-ready bg-warning"
            if (task_status == "NOT_READY") return "task-not-ready"
            if (task_status == "IN_PROGRESS") return "task-in-progress bg-info"
            if (task_status == "DONE") return "task-done"
        },

        openHistoryModal(task) {
            this.selectedTask = task
            this.$refs.modalWorkHistory.showModal();
            // this.$nextTick(() => {
            // })
        },


        getWorkablePositionName(item) {
            if (!item.position_unit_obj) return item.name;

            var name = item.position_unit_obj.name
            if (item.name) name += ' (' + item.name + ')';
            return name;
            // return item.name
        },

        updateTask(task) {
            if (this.selectedTask?.id === task?.id){
                let newselected = UtilService.objectDeepAssign(this.selectedTask, task)
                console.log(newselected);
            }
        },


        commandClick(event) {
            console.log(event);
        },

        ///////////

        async getWorkOrderTasksGrid() {
            try {
                let response = await WorkOrderService.getWorkOrderTasksGrid({url: {workOrderId: this.workOrderId}})
                let tasksGridData = response.data
                this.tasksGridData = tasksGridData


            } catch (error) {
                console.log(error);
            }

        },

        async loadData() {
            await this.getWorkOrderTasksGrid()
        },

    },


    created() {
        this.loadData();
    },

    mounted() {
        // UtilService.activateDragMove('works-grid-table')
    }

}
</script>

<style scoped>

.pos-loc-table {
    height: 100vh;
}

.pos-loc-table .row-location {
    background:rgb(230, 230, 230);
}

.pos-loc-table .row-location>td {
    padding-top: 0;
    padding-bottom: 0;
}

/* .pos-loc-table .btn-work-status {
    width: 200px;
} */

.pos-loc-table .selected-location {
    font-weight: bold;
}

.pos-loc-table .workable {
    font-weight: bold;
    max-width: 300px;
    display: block;
    overflow: auto;
}

.pos-loc-table .work-info {
    min-width: 120px;
}

.pos-loc-table .sub-group-pos {
    font-size: 0.8em;
    max-width: 100px;
    display: block;
    overflow: auto;
}

.table > thead > tr {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
}

.table > tbody .sticky-col {
    position: sticky;
    left: 0;
    background: #fff;
    /* min-width: 200px; */
    border-right: 1px solid rgb(218, 218, 218);
    z-index: 5;
}

.table > thead > tr > th:first-child {
    position: sticky;
    top: 0;
    left: 0;
    background: white;
    z-index: 20;
}

.table > tbody > tr > .sticky-col::after {
    content: '';
    position: absolute;
    background: #dee2e6;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
}

.bg-green-yellow-strip {
    background: repeating-linear-gradient(
    45deg,
    #19dc81,
    #19dc81 10px,
    #b88a01 10px,
    #b88a01 20px
    );
}

.bg-red-yellow-strip {
    background: repeating-linear-gradient(
    45deg,
    #ff7b7b,
    #ff7b7b 10px,
    #b88a01 10px,
    #b88a01 20px
    );
}

.work-info > .work-ready {
    background: yellow !important;
}
.work-info > .approved-finished {
    background: #00ff00 !important;
}

</style>