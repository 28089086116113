<template>
  <div>
    <Navbar />
    <Breadcrumbs></Breadcrumbs>
    <PositionLocationGridView/>

  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import PositionLocationGridView from '@/components/work-orders/PositionLocationGridView.vue'

export default {
  components: {
    Navbar,
    PositionLocationGridView
  }
}
</script>
